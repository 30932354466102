const VkPixel = () => (
  <>
    <script dangerouslySetInnerHTML={{__html:`
      !function(){var t=document.createElement("script");t.type="text/javascript",t.async=!0,t.src="https://vk.com/js/api/openapi.js?168",t.onload=function(){VK.Retargeting.Init("VK-RTRG-659088-c5MJE"),VK.Retargeting.Hit()},document.head.appendChild(t)}();
    `}}/>
    <noscript dangerouslySetInnerHTML={{__html:`
      <img src="https://vk.com/rtrg?p=VK-RTRG-659088-c5MJE" style="position:fixed; left:-999px;" alt=""/> 
    `}}/>
  </>
);

export default VkPixel;
