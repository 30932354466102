import React from 'react';
import cn from 'classnames';

import Link from 'next/link';

import styles from './Button.module.scss';

interface ButtonProps {
  placeholder: string;
  addStyles?: string[];
  onClick?: () => void;
  href?: string;
  disabled?: boolean;
  type?: any;
  target?: string;
}

const Button: React.FC<ButtonProps> = ({
  placeholder,
  addStyles = [],
  onClick,
  href,
  disabled,
  type,
  target,
}) => {
  const style = cn(styles.button, ...addStyles);

  const Tag = href ? 'a' : 'button';

  return (
    <>
      {Tag === 'a' ? (
        <>
          {href.indexOf('https://') === 0 ? (
            <Tag
              className={style}
              onClick={onClick}
              href={href}
              type={type}
              target={target}
            >
              {placeholder}
            </Tag>
          ) : (
            <Link href={href}>
              <Tag
                className={style}
                onClick={onClick}
                href={href}
                type={type}
                target={target}
              >
                {placeholder}
              </Tag>
            </Link>
          )}
        </>
      ) : (
        <Tag
          className={style}
          onClick={onClick}
          type={type}
          disabled={disabled}
        >
          {placeholder}
        </Tag>
      )}
    </>
  );
};

export default Button;
